import React from "react";
import "../components/clientblockinplacement.css";

export default function TrustedByInPlacement({ trustedData }) {
  const styles = {
    firstDiv: {
      backgroundColor: "#fff",
    },
    container: {
      backgroundColor: "#fff",
    },
    clientLogosBlock: {
      backgroundColor: "#fff",
      padding: "0.4rem",
      borderRadius: "4px",
      boxShadow: "0px 20px 20px #1f305d12",
      top: "-3.5rem",
    },
    clientLogosTxt: {
      textAlign: "center",
    },
  };

  return (
    <>
      <div className="firstdiv" style={styles.firstDiv}>
        <div className="container" style={styles.container}>
          <div id="clients" style={styles.clients}>
            <div className="">
              <div
                className="client-logos-block"
                style={styles.clientLogosBlock}
              >
                <div className="row m-0">
                  <div className="col-xl-12 p-0">
                    <div className="row m-0">
                      <div
                        className="client-logos-txt col-lg-3"
                        style={styles.clientLogosTxt}
                      >
                        <h4>Java Home Cloud </h4>
                        <h3>Trusted By</h3>
                      </div>
                      <div className="client-logos col-lg-9">
                        {trustedData &&
                          trustedData.map(({ title, imgSrc }, idx) => (
                            <img
                              src={imgSrc}
                              alt={title}
                              key={idx}
                              loading="lazy"
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
