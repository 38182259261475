import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import socialActivityBanner from "../../images/social-detail-view.png";
import SocialService from "../../images/social-service.png";
export default function SocialActivityDetails() {
  return (
    <div className="workshop-detail-view">
      <div className="workshop-detail-sub-banner social_detail_view">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="workshop-detail-heading">
                <Breadcrumb>
                  <Breadcrumb.Item href="/#/socialActivity">
                    Social Activity
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Social Activity View</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="h1-large">
                  Social Activities from Java home cloud
                </h1>
              </div>
              <div className="workshop-detail-banner">
                <img
                  src={socialActivityBanner}
                  alt="social-detail-view"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="social-service">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="ws-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  molestie, mauris quis dapibus lobortis, mauris enim faucibus
                  quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam
                  vel porta rhoncus, orci massa eleifend velit, sit amet
                  vehicula odio tellus in magna. Cras et posuere diam. Aliquam
                  suscipit dolor sit amet mauris rhoncus feugiat. Morbi ac diam
                  ut purus dapibus ultrices. Morbi venenatis accumsan elit vitae
                  vestibulum. Vivamus mattis consectetur justo sed dictum. In
                  viverra consequat felis vel mattis. Cras ut facilisis nulla,
                  sit amet condimentum sem.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <p className="ws-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  molestie, mauris quis dapibus lobortis, mauris enim faucibus
                  quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam
                  vel porta rhoncus, orci massa eleifend velit, sit amet
                  vehicula odio tellus in magna. Cras et posuere diam. Aliquam
                  suscipit dolor sit amet mauris rhoncus feugiat. Morbi ac diam
                  ut purus dapibus ultrices. Morbi venenatis accumsan elit vitae
                  vestibulum. Vivamus mattis consectetur justo sed dictum. In
                  viverra consequat felis vel mattis. Cras ut facilisis nulla,
                  sit amet condimentum sem.
                </p>
                <p className="ws-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  molestie, mauris quis dapibus lobortis, mauris enim faucibus
                  quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam
                  vel porta rhoncus, orci massa eleifend velit, sit amet
                  vehicula odio tellus in magna. Cras et posuere diam.
                </p>
                <p className="ws-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  molestie, mauris quis dapibus lobortis, mauris enim faucibus
                  quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam
                  vel porta rhoncus, orci massa eleifend velit, sit amet
                  vehicula odio tellus in magna. Cras et posuere diam.
                </p>
              </div>
              <div className="col-lg-5">
                <img
                  src={SocialService}
                  alt="SocialService"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
