import React, { useState, useEffect, useRef } from "react";
import HomeCarousel from "../home-carousel";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import validator from "validator";
import AxiosClient from "../../js/axiosClient";
import YoutubePlayer from "./youtube-player";

import testimonial1 from "../../images/testimonial-1.jpg";
import carousel1 from "../../images/Harish-img.jpg";
import testimonial3 from "../../images/testimonial-3.jpg";
import bannerImg from "../../images/Banner.png";

const axiosClient = new AxiosClient({});

const carouselData = [
  {
    name: "AWS Certified Class",
    src: carousel1,
    time: "Today at 12.00 PM",
    duration: 1000,
    link: "/courses/aws-solutions-architect-associate/details",
  },
  {
    name: "Devops",
    src: testimonial3,
    time: "Tomorrow at 04.15 PM",
    duration: 1000,
    link: "/courses/devops-master-course/details",
  },
  {
    name: "Microsoft Azure",
    src: testimonial1,
    time: "Today at 10.30 AM",
    duration: 1000,
    link: "/courses/azure-devops/details",
  },
];

export default function HomeSlider() {
  // Enquiry form
  const [EmailError, setEmailError] = useState("");
  const [state, setState] = useState({
    email: "",
  });
  const [isOpened, setIsOpened] = useState(false);
  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: state.email,
    };
    let url = process.env.REACT_APP_API_BASE_URL + "subscribe";
    let data = userData;
    axiosClient
      .request(
        {
          url: url,
          method: "POST",
          headers: {
            "X-Channel": "app",
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
          data: data,
        },
        setState("")
      )
      .then((resp) => {
        if (validator.isEmail(state.email)) {
          setEmailError("Valid Email :)");
          changeText("Subscribed...");
          setIsOpened((wasOpened) => !wasOpened);
          setTimeout(() => {
            setEmailError("");
            setIsOpened(false);
            setState({ email: "" });
          }, [6000]);
        } else {
          setEmailError("Enter valid Email!");
        }
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialState = "Get Started";
  const [buttonText, setButtonText] = useState(initialState);
  const changeText = (text) => {
    setButtonText(text);
    setTimeout(() => setButtonText(initialState), [2000]);
  };
  const messageRef = useRef("");

  useEffect(() => {
    messageRef.current = state;
  }, [state]);

  return (
    <header id="JavaHomeHeader" className="header">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-xl-7">
            <div className="text-container">
              <h2 className="h1-large">
                Unlock your career potential with in-demand IT skills.
              </h2>
              <p className="p-large">Start learning today</p>

              <div className="banner-input-field">
                <Form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    className="form-control-input"
                    placeholder="Email"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                    required
                  />
                  <Button className="btn btn-cut" type="submit">
                    {buttonText}
                  </Button>

                  {isOpened && (
                    <div className="SuccessMessage">
                      Thank You Successfully Subscribed!
                    </div>
                  )}
                </Form>
              </div>
              <div className="banner-tick">
                <ul>
                  <li>Skills and Experience Coaches.</li>
                  <li>Support 1:1 Coaching</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-xl-5">
            <div className="image-container video-btn">
              <button type="button" className="btn" onClick={handleShow}>
                video
              </button>
              <img
                className="img-fluid b-img"
                src={bannerImg}
                alt="alternative"
                loading="eager"
              />
            </div>
            <div id="bannerCarousel" className="carousel slide">
              <HomeCarousel carouselData={carouselData} />
            </div>
          </div>
        </div>
      </div>
      <YoutubePlayer show={show} handleClose={handleClose} />
    </header>
  );
}
