import "./App.css";
import ReactGA from "react-ga4";
import useClarity from "./tools/ms_clarity";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Header from "./components/header";
import AboutUs from "./pages/aboutUs";
import Footer from "./components/footer";

import Courses from "./pages/courses";
import Workshop from "./pages/workshop";
import Placements from "./pages/placements";
import PrivacyPolicy from "./pages/privacyPolicy";
import ContactForm from "./components/contact-form";
import CourseDetails from "./pages/courseDetails";
import WorkshopDetails from "./pages/workshopDetails";
import SocialActivity from "./pages/socialActivity";
import CollageTraining from "./pages/collageTraining";
import SocialActivityDetails from "./pages/socialActivityDetails";
import Verify from "./pages/verify";
import Certificate from "./components/certificate/Certificate";
import RobotoChat from "./components/wati-chat-assistance";
import BookYourSlot from "./pages/courseDetails/bookYourSlot";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
function App() {
  useClarity();

  const isDevEnvironment = process.env.REACT_APP_ENV === "dev";

  return (
    <BrowserRouter>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route exact path="/courses/:courseId/details" element={<CourseDetails />}/>
        <Route exact path="/courses/:courseId/details/bookYourSlot" element={<BookYourSlot/>} /> 
        <Route path="/placements" element={<Placements />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/socialActivity" element={<SocialActivity />} />
        <Route path="/collageTraining" element={<CollageTraining />} />
        <Route exact path="/socialActivityDetails" element={<SocialActivityDetails />}/>
        <Route exact path="/workshop/:courseId/details" element={<WorkshopDetails />}/>
        <Route path="/about-us" element={<AboutUs />} />
        {isDevEnvironment &&<Route path="/verify" element={<Verify />}/>}
        {isDevEnvironment &&<Route path="/verify/:certificateId" element={<Verify />} />}
        <Route path="/certificate/:certificateId" component={Certificate} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<h1>Page Not found</h1>} />
      </Routes>

      <RobotoChat/>
      <ContactForm/>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
