import React from "react";
import { Link } from "react-router-dom";
import SocialA1 from "../../images/social-a1.jpg";
import SocialA2 from "../../images/social-a2.jpg";

const activityList = [
  { activity: "Social Activities from Java home cloud", image: SocialA1 },
  { activity: "Social Activities from Java home cloud", image: SocialA2 },
  { activity: "Social Activities from Java home cloud", image: SocialA1 },
  { activity: "Social Activities from Java home cloud", image: SocialA2 },
  { activity: "Social Activities from Java home cloud", image: SocialA1 },
  { activity: "Social Activities from Java home cloud", image: SocialA2 },
  { activity: "Social Activities from Java home cloud", image: SocialA1 },
  { activity: "Social Activities from Java home cloud", image: SocialA1 },
];

export default function SocialActivity() {
  // const navigate = useNavigate();

  // const handleSelected = (id) => {
  //   navigate(`/socialActivity/${id}/details`);
  // };

  return (
    <div className="social-page">
      <div className="sub-banner">
        <h1 className="h1-large">Get Inspired Today By Social Activities</h1>
      </div>

      <div className="social-activity-section">
        <div className="container">
          <h3>Our Social Activities</h3>
          <p className="social-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
            molestie, mauris quis dapibus lobortis, mauris enim faucibus quam,
            eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel porta
            rhoncus, orci massa eleifend velit, sit amet vehicula odio tellus in
            magna. Cras et posuere diam. Aliquam suscipit dolor sit amet mauris
            rhoncus feugiat. Morbi ac diam ut purus dapibus ultrices. Morbi
            venenatis accumsan elit vitae vestibulum. Vivamus mattis consectetur
            justo sed dictum. In viverra consequat felis vel mattis. Cras ut
            facilisis nulla, sit amet condimentum sem.
          </p>
          <div className="row">
            {activityList &&
              activityList.map(({ activity, image }, idx) => (
                <div
                  className="col-lg-3 col-md-6 md-md-4"
                  key={idx}
                  style={{ marginBottom: "20px" }}
                >
                  <Link className="social-a" to="/socialActivityDetails">
                    <div className="post-img">
                      <img src={image} alt="" className="img-fluid" loading="lazy"/>
                    </div>
                    <h2 className="social-a-title">{activity}</h2>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
