import React, { useState, useEffect } from "react";
import FilterTabs from "../../components/filter-tabs";
import AccessCoursesImage from "../../images/access-coures-img.svg";
import { FaSearch } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import UpcomingBatches from "../../components/upcoming-batches";
import ReactGA from "react-ga4";

export default function Courses() {
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className="course-page">
      <div className="sub-banner">
        <h1 className="h1-large">Explore Popular Courses</h1>
      </div>
      <div className="course-tabs">
        <div className="container cts-c">
          <div className="search-course">
            <input
              className="search-input-box"
              placeholder="Search"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span>
              <FaSearch />
            </span>
          </div>
        </div>
        <FilterTabs
          page="courses"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
      <div className="access-courses">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xl-4">
              <div className="review-main">
                <div className="image-container">
                  <img
                    className="img-fluid"
                    src={AccessCoursesImage}
                    alt="access courses"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-xl-8 course_points">
              <h2>Access to all 50+ offline and online courses</h2>
              <div className="banner-tick">
                <ul>
                  <li>Get Unlimited Access to Top Courses</li>
                  <li>Explore a Variety of Fresh Topics</li>
                  <li>Find the Right Instructor for You</li>
                  <li>Stay Current with Continuous Exploration</li>
                  <li>Diverse Learning for Diverse Minds</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
        <UpcomingBatches />
    </div>
  );
}
